"use client";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { useState } from "react";


interface ContactFormData {
  name: string;
  email: string;
  message: string;
}

interface ContactUsProps {
  email?: string;
}

export default function ContactUs({ email }: ContactUsProps) {
  const [formData, setFormData] = useState<ContactFormData>({
    name: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<{
    type: "success" | "error" | null;
    message: string;
  }>({ type: null, message: "" });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      const response = await fetch("/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          to:  email || "support@yourfirststore.com"
        }),
      });

      if (!response.ok) throw new Error("Failed to submit");

      setSubmitStatus({
        type: "success",
        message: "Message sent successfully!",
      });
      setFormData({ name: "", email: "", message: "" });
    } catch (error) {
      setSubmitStatus({
        type: "error",
        message: "Failed to send message. Please try again.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="bg-background py-16">
      <div className="container mx-auto px-4 max-w-lg">
        <div className="text-center mb-10">
          <h2 className="text-primary text-3xl font-bold mb-4">
            Contact Us
          </h2>
          <p className="text-foreground">
            Have questions? We'd love to hear from you.
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <Label 
              htmlFor="name" 
              className="text-foreground"
            >
              Name
            </Label>
            <Input
              id="name"
              type="text"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}

              placeholder="Your name"
              className="mt-1"
            />
          </div>

          <div>
            <Label 
              htmlFor="email"
              className="text-foreground"
            >
              Email
            </Label>
            <Input
              id="email"
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}

              placeholder="your@email.com"
              className="mt-1"
            />
          </div>

          <div>
            <Label 
              htmlFor="message"
              className="text-foreground"
            >
              Message
            </Label>
            <Textarea
              id="message"
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}

              placeholder="Your message"
              className="mt-1"
              rows={5}
            />
          </div>

          {submitStatus.message && (
            <div
              className={`p-4 rounded-md ${
                submitStatus.type === "success" 
                  ? "bg-secondary/10 text-primary"
                  : "bg-destructive/10 text-destructive"
              }`}
            >
              {submitStatus.message}
            </div>
          )}

          <Button
            type="submit"
            className="w-full bg-blue-600 hover:bg-blue-700 text-lg px-8 py-6"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Sending..." : "Send Message"}
          </Button>
        </form>
      </div>
    </section>
  );
}
